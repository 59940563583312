import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { css } from '@emotion/react'
import color from '@/constants/color'
import shmColor from '@/components/lp/scalehack/color'
import { mq } from '@/components/media/media'
import arrow from '@/images/arrow_blue.svg'
import ReCAPTCHA from 'react-google-recaptcha'

type FormData = {
  '氏名': string
  '生年月日': string
  'メールアドレス': string
  '電話番号': number
  '所属企業名': string
}

export const Form = () => {
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm<FormData>()
  const contactForm = useRef<HTMLFormElement>(null)
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const [showMessage, setShowMessage] = useState(false)
  const onSubmit = handleSubmit(() => {
    const recaptchaValue = recaptchaRef.current?.getValue() || ''

    if (!recaptchaValue.length) {
      setShowMessage(true)
    } else {
      contactForm.current?.submit()
    }
  })
  const [isChecked, setIsChecked] = useState(false)
  const toggleCheckbox = () => {
    setIsChecked(!isChecked)
  }

  return (
    <>
      <form
        noValidate
        onSubmit={onSubmit}
        ref={contactForm}
        action="https://form.run/api/v1/r/hfkbdar9chuy2fq9atgtqnfy"
        method="post"
      >
        <label css={label}>
          <span css={labelTItleRequired}>氏名</span>
          <input
            css={input}
            type="text"
            placeholder=""
            {...register('氏名', {
              required: '必須項目に入力してください。'
            })}
          />
          <ErrorMessage
            errors={errors}
            name={'氏名'}
            render={({ message }) => <p css={errorText}>{message}</p>}
          />
        </label>
        <label css={label}>
          <span css={labelTItleRequired}>生年月日</span>
          <input
            css={input}
            type="number"
            placeholder="例：19901205 ※1990年12月5日生まれの場合"
            {...register('生年月日', {
              required: '必須項目に入力してください。',
              pattern: {
                value:
                /^\d{8}$/,
                message: 'YYYYMMDD形式の数字8桁でご入力ください。'
              },
              validate: (value: string) => {
                const dateString = `${value.slice(0, 4)}-${value.slice(4, 6)}-${value.slice(6, 8)}`
                const birthDate: Date = new Date(dateString)
                if (Number.isNaN(birthDate.getTime())) {
                  return '不正な日付が入力されています。'
                }
                return true;
              }
            })}
          />
          <ErrorMessage
            errors={errors}
            name={'生年月日'}
            render={({ message }) => <p css={errorText}>{message}</p>}
          />
        </label>
        <label css={label}>
          <span css={labelTItleRequired}>メールアドレス</span>
          <input
            css={input}
            type="email"
            placeholder=""
            {...register('メールアドレス', {
              required: '必須項目に入力してください。',
              pattern: {
                value:
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: '入力されたメールアドレスに間違いがあります。'
              }
            })}
          />
          <ErrorMessage
            errors={errors}
            name={'メールアドレス'}
            render={({ message }) => <p css={errorText}>{message}</p>}
          />
        </label>
        <label css={label}>
          <span css={labelTItleRequired}>電話番号</span>
          <input
            css={input}
            type="tel"
            placeholder="例：09012345678"
            {...register('電話番号', {
              required: '必須項目に入力してください。'
            })}
          />
          <ErrorMessage
            errors={errors}
            name={'電話番号'}
            render={({ message }) => <p css={errorText}>{message}</p>}
          />
        </label>
        <label css={label}>
          <span css={labelTItleRequired}>所属企業名</span>
          <input
            css={input}
            placeholder=""
            {...register('所属企業名', {
              required: '必須項目に入力してください。'
            })}
          />
          <ErrorMessage
            errors={errors}
            name={'所属企業名'}
            render={({ message }) => <p css={errorText}>{message}</p>}
          />
        </label>
        <label css={labelCheckbox}>
          <input
            css={checkbox}
            type="checkbox"
            onChange={() => toggleCheckbox()}
          />
          <span css={labelTitleCheckbox}>
            株式会社Scalehackの
            <a
              css={textButtonBlank}
              href="/group/scalehack/policy"
              target="_blank"
            >
              プライバシーポリシー
              <span>
                <img src={arrow} alt="外部ページへのリンク" />
              </span>
            </a>
            に同意する
          </span>
        </label>
        <div css={recaptchaContainer}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6Lc1WqofAAAAAAPSsvJX6S_0IBR1qqSbAP_7DQV6"
          />
        </div>
        <p css={centerErrorText}>
          {showMessage ? 'あなたがロボットでないことを確認してください。' : ''}
        </p>
        <button css={button} type="submit" disabled={!isChecked}>
          送信する
        </button>
      </form>
    </>
  )
}

const label = css`
  display: block;
  margin-bottom: 22px;
`

const labelCheckbox = css`
  ${label}

  display: flex;
  justify-content: center;
  margin: 40px 0;
  letter-spacing: 1.6px;
`

const labelTitle = css`
  display: block;
  padding-bottom: 11px;
  font-size: 16px;

  ${mq.pc} {
    padding-bottom: 15px;
  }
`

const labelTitleCheckbox = css`
  ${labelTitle}

  display: inline;
  padding: 0 0 0 28px;

  ${mq.pc} {
    padding-bottom: 0;
  }
`

const labelTItleRequired = css`
  ${labelTitle}

  &::before {
    position: relative;
    color: ${color.red2};
    content: '【必須】';
  }
`

const input = css`
  width: 100%;
  padding: 0 12px;
  font-size: 16px;
  line-height: 48px;
  background-color: ${color.white1};
  border: ${color.gray1} 1px solid;
  border-radius: 5px;
`

const checkbox = css`
  display: none;

  + span {
    position: relative;
  }
  + span::before {
    position: absolute;
    top: 1px;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    content: '';
    background-color: ${color.white1};
    border: ${color.gray4} 1px solid;
    border-radius: 3px;
  }

  &:checked {
    + span::before {
      background: ${color.blue6};
    }

    + span::after {
      position: absolute;
      top: 4px;
      left: 7px;
      width: 6px;
      height: 12px;
      content: '';
      border-right: ${color.white1} 2px solid;
      border-bottom: ${color.white1} 2px solid;
      transform: rotate(45deg);
    }
  }
`

const errorText = css`
  margin-top: 4px;
  font-size: 16px;
  color: ${color.red2};
`

const centerErrorText = css`
  ${errorText}
  text-align: center;
`

const textButtonBlank = css`
  position: relative;
  display: inline;
  color: ${color.blue6};
  border-bottom: ${color.blue6} 1px solid;

  span {
    display: inline-block;
    width: 14px;
    height: 14px;
    line-height: 1;
  }

  img {
    vertical-align: top;
  }
`

export const button = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 200px;
  height: 50px;
  margin: 40px auto 0;
  font-size: 18px;
  font-weight: 600;
  color: ${color.white1};
  background-color: ${shmColor.red1};
  border-radius: 90px;

  &:disabled {
    opacity: 0.7;
  }

  ${mq.pc} {
    max-width: 240px;
    height: 70px;
  }
`

const recaptchaContainer = css`
  display: flex;
  justify-content: center;
`
