import React from 'react'
import { css } from '@emotion/react'
import color from '@/components/lp/scalehack/color'
import { mq } from '@/components/media/media'
import { Form } from './form'

export const Apply = () => {
  return (
    <main css={main}>
      <section css={section}>
        <h1 css={title}>
          <span css={jpTitle}>ご登録</span>
          <span css={enTitle}>CONTACT</span>
        </h1>
        <Form />
      </section>
    </main>
  )
}

const main = css`
  margin-bottom: -2rem;
  background-color: ${color.grayfF8};

  ${mq.pc} {
    margin-bottom: -4rem;
  }
`

const section = css`
  width: 100%;
  max-width: 632px;
  padding: 96px 16px 48px;
  margin: 0 auto;
  color: ${color.brack111};

  ${mq.pc} {
    padding: 172px 16px 120px;
  }
`

const title = css`
  padding-bottom: 38px;
  text-align: center;

  ${mq.pc} {
    padding-bottom: 46px;
  }
`

const jpTitle = css`
  display: block;
  padding-bottom: 21px;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 2.2px;
`

const enTitle = css`
  display: block;
  font-family: 'Helvetica', sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: ${color.red1};
  letter-spacing: 1.5px;
`
