import React from 'react'
import { Layout } from '@/components/lp/scalehack/for-marketing/layout'
import { Meta } from '@/components/lp/scalehack/for-marketing/meta'
import { Header } from '@/components/lp/scalehack/for-marketing/freelance/header'
import { Apply } from '@/components/lp/scalehack/for-marketing/freelance/apply'

export default () => {
  return (
    <>
      <Layout>
        <Meta
          title="ご登録 | Scalehack for Marketing :ハイクラス人材が提供するマーケティング支援"
          description="Scalehack for Marketingは、&quot;好条件&quot;なだけでなく、ハイクラスな人材と共創し成長できる新しいフリーランス・複業の案件が見つかるサービスです。人材派遣 / 案件紹介型のサービスとは全く異なる、新しい「チーム体制型」のフリーランス・複業での働き方を提案します。"
          script={['https://sdk.form.run/js/v2/formrun.js']}
        />
        <Header />
        <Apply />
      </Layout>
    </>
  )
}
