export const mq = {
  large: '@media screen and (min-width: 1900px)',
  mid: '@media screen and (min-width: 1020px)',
  pc: '@media screen and (min-width: 768px)',
  tablet: '@media screen and (min-width: 608px)',
  short: '@media screen and (max-height: 480px)',

  midScalehack: '@media screen and (min-width: 1250px)',
  pcScalehack: '@media screen and (max-width: 1249px) and (min-width: 768px)',

  midSHM: '@media screen and (max-width: 1199px)',
  prefers: '@media screen and (prefers-reduced-motion: reduce)'
}
